class MmdAccountModel {
  private accountNumber: string;
  private accountName: string;
  private newExistingCIdNumber: string;
  private accountBranchNumber: string;
  private accountCurrency: string;
  private lineOfBusiness: string;
  private accountType: string;
  private accountCostCenterNumber: string;
  private accountEntityCode: string;
  private accountGlCategoryCode: string;
  private industryClass: string;
  private networkIndicator: string;
  private legalEntityCash: string;
  private businessType: string;
  private compId111CostCenter: string;
  private foreignGovernmentAccount: boolean;
  private usBranch31CashReserve: boolean;
  private reservedStatus: boolean;
  private isGbpHybrid: boolean;
  private isAccountStif: boolean;
  private isUsageCres: boolean;
  private cnyOffShore: boolean;

  constructor(
    accountNumber: string,
    accountBranchNumber: string,
    accountName: string,
    newExistingCIdNumber: string,
    accountCurrency: string,
    lineOfBusiness: string,
    accountType: string,
    accountCostCenterNumber: string,
    accountEntityCode: string,
    accountGlCategoryCode: string,
    industryClass: string,
    networkIndicator: string,
    legalEntityCash: string,
    businessType: string,
    compId111CostCenter: string,
    foreignGovernmentAccount: boolean,
    usBranch31CashReserve: boolean,
    reservedStatus: boolean,
    isGbpHybrid: boolean,
    isAccountStif: boolean,
    isUsageCres: boolean,
    cnyOffShore: boolean
  ) {
    this.accountNumber = accountNumber;
    this.accountBranchNumber = accountBranchNumber;
    this.accountName = accountName;
    this.newExistingCIdNumber = newExistingCIdNumber;
    this.accountCurrency = accountCurrency;
    this.lineOfBusiness = lineOfBusiness;
    this.accountCostCenterNumber = accountCostCenterNumber;
    this.accountType = accountType;
    this.accountGlCategoryCode = accountGlCategoryCode;
    this.industryClass = industryClass;
    this.networkIndicator = networkIndicator;
    this.legalEntityCash = legalEntityCash;
    this.businessType = businessType;
    this.compId111CostCenter = compId111CostCenter;
    this.foreignGovernmentAccount = foreignGovernmentAccount;
    this.usBranch31CashReserve = usBranch31CashReserve;
    this.isGbpHybrid = isGbpHybrid;
    this.accountEntityCode = accountEntityCode;
    this.isAccountStif = isAccountStif;
    this.reservedStatus = reservedStatus;
    this.isUsageCres = isUsageCres;
    this.cnyOffShore = cnyOffShore;
  }

  validate() {
    if (this.accountNumber === null) {
      return "Account Number is required";
    }
    if (this.accountBranchNumber === null) {
      return "Account Branch Number is required";
    }
    if (this.accountName === null) {
      return "Account Name is required";
    }
    if (this.newExistingCIdNumber === null) {
      return "New Existing CId Number is required";
    }
    if (this.accountCurrency === null) {
      return "Account Currency is required";
    }
    if (this.lineOfBusiness === null) {
      return "Line of Business is required";
    }
    if (this.accountCostCenterNumber === null) {
      return "Account Cost Center Number is required";
    }
    if (this.accountType === null) {
      return "Account Type is required";
    }
    if (this.accountGlCategoryCode === null) {
      return "Account Category Code is required";
    }
    if (this.industryClass === null) {
      return "Industry Class is required";
    }
    if (this.networkIndicator === null) {
      return "Network Indicator is required";
    }
    if (this.legalEntityCash === null) {
      return "Legal Entity Cash is required";
    }
    if (this.businessType === null) {
      return "Business Type is required";
    }
    if (this.compId111CostCenter === null) {
      return "Comp Id 111 Cost Center is required";
    }
    if (this.usBranch31CashReserve === null) {
      return "US Branch 31 Cash Reserve is required";
    }
    if (this.foreignGovernmentAccount === null) {
      return "Foreign Government Account is required";
    }
    if (this.isGbpHybrid === null) {
      return "Is GBP Hybrid is required";
    }
    if (this.isAccountStif === null) {
      return "Is Account Stif is required";
    }
    if (this.accountEntityCode === null) {
      return "Account Entity Code is required";
    }
    return null;
  }

  toJson() {
    return {
      accountNumber: this.accountNumber,
      accountBranchNumber: this.accountBranchNumber,
      accountName: this.accountName,
      newExistingCIdNumber: this.newExistingCIdNumber,
      accountCurrency: this.accountCurrency,
      lineOfBusiness: this.lineOfBusiness,
      accountCostCenterNumber: this.accountCostCenterNumber,
      accountType: this.accountType,
      accountGlCategoryCode: this.accountGlCategoryCode,
      industryClass: this.industryClass,
      networkIndicator: this.networkIndicator,
      legalEntityCash: this.legalEntityCash,
      businessType: this.businessType,
      compId111CostCenter: this.compId111CostCenter,
      foreignGovernmentAccount: this.foreignGovernmentAccount,
      usBranch31CashReserve: this.usBranch31CashReserve,
      isGbpHybrid: this.isGbpHybrid,
      accountEntityCode: this.accountEntityCode,
      isAccountStif: this.isAccountStif,
      reservedStatus: this.reservedStatus,
      isUsageCres: this.isUsageCres,
      cnyOffshore: this.cnyOffShore,
    };
  }
}
export default MmdAccountModel;
