import apiClient from "../types/ApiClient";
import { AccountDetails } from "../types/AccountDetails";
import MmdAccountModel from "../types/MmdAccountModel";
import TableData from "../types/TableData";
import ImmsAccountResponse from "../types/ImmsAccountResponse";

export const getAccount = async (accountNumber) => {
  try {
    const response = await apiClient.get(`/accounts/${accountNumber}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createAccount = async (accountInfo) => {
  let accountDetails: AccountDetails[] = accountInfo.accountDetails;
  let responseList: ImmsAccountResponse[] = [];
  if (!Array.isArray(accountDetails)) {
    throw new TypeError("accountDetails is not iterable");
  }

  for (const account of accountDetails) {
    let accountModel = new MmdAccountModel(
      account.accountNumber,
      accountInfo.accountBranchNumber,
      account.accountName,
      account.newExistingCIdNumber,
      accountInfo.accountCurrency,
      accountInfo.lineOfBusiness,
      accountInfo.accountType,
      accountInfo.accountCostCenterNumber,
      accountInfo.accountEntityCode,
      accountInfo.accountGlCategoryCode,
      accountInfo.industryClass,
      accountInfo.networkIndicator,
      accountInfo.legalEntityCash,
      accountInfo.businessType,
      accountInfo.compId111CostCenter,
      accountInfo.foreignGovernmentAccount,
      accountInfo.usBranch31CashReserve,
      accountInfo.reservedStatus,
      accountInfo.isGbpHybrid,
      accountInfo.isAccountStif,
      accountInfo.isUsageCres,
      accountInfo.cnyOffShore
    );
    try {
      const response = await apiClient.post(`/`, accountModel, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      responseList.push(
        new ImmsAccountResponse(
          account.accountNumber,
          account.accountName,
          response.data?.metadata?.success == "false" ? "Failure" : "Success",
          response.data?.metadata?.description ||
            "Success - Account created successfully"
        )
      );
    } catch (error) {
      responseList.push(
        new ImmsAccountResponse(
          account.accountNumber,
          account.accountName,
          "Failure",
          error.response?.data?.metadata?.description ||
            "An unknown error occurred"
        )
      );
    }
  }
  return responseList.map(
    (response) =>
      new TableData(
        response.accountNumber,
        response.accountName,
        response.success,
        response.description
      )
  );
};

export const updateAccount = async (accountNumber, accountInfo) => {
  try {
    const response = await apiClient.put(
      `/accounts/${accountNumber}`,
      accountInfo
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
