import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";


function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/mmd-account-adapter-frm" element={<Home />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;