export default class TableData {
  private accountNumber: string;
  private accountName: string;
  private success: string;
  private description: string;

  constructor(
    accountNumber: string,
    accountName: string,
    success: string,
    description: string
  ) {
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.success = success;
    this.description = description;
  }
}
