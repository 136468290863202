import { Form } from "@formio/react";
import formImport from "../resources/mmd-account.json";
import "../styles/formrenderer.css";
import "../styles/tablestyle.css";
import "../styles/loading-dialogue.css";
import { Container, Col, Row, Button } from "react-bootstrap";
import React, { useState } from "react";
import { createAccount, getAccount } from "./service/Mmd-Account-Service";
import MmdAccountFormModel from "./types/MmdAccountFormModel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "@fortawesome/fontawesome-free/css/all.min.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Chip, CircularProgress } from "@mui/material";

function FormRenderer() {
  const [mmdAccountFormModel, setMmdAccountFormModel] = useState(null);
  const [openRequestDialog, setOpenRequestDialog] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFormChange = (event) => {
    setMmdAccountFormModel(event.data);
  };

  const submitForm = async (formData) => {
    setLoading(true);
    try {
      const response = await createAccount(formData.toJson());
      if (response !== null) {
        setLoading(false);
        setOpenRequestDialog(true);
        setRows(response);
      }
    } catch (error) {
      setLoading(false);
      setOpenRequestDialog(true);
    }
  };

  const handleGet = async () => {
    try {
      const response = await getAccount(mmdAccountFormModel.accountNumber);
    } catch (error) {
      setErrorMessage(error.toString());
      setOpenErrorDialog(true);
    }
  };

  const handleSubmitForm = (submission) => {
    try {
      const submittedForm = new MmdAccountFormModel(submission.data);
      if (submittedForm.validate() === null) {
        setMmdAccountFormModel(submittedForm);
        submitForm(submittedForm);
      }
    } catch (error) {
      setErrorMessage(error.toString());
      setOpenErrorDialog(true);
    }
  };

  const handleCloseRequestDialog = () => {
    setOpenRequestDialog(false);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="page-headers">Cash Account Form</h2>
          <div className="main">
            <Form
              form={formImport}
              onChange={onFormChange}
              onSubmit={handleSubmitForm}
            />
          </div>
        </Col>
      </Row>
      <React.Fragment>
        <Dialog
          open={openRequestDialog}
          keepMounted
          onClose={handleCloseRequestDialog}
          maxWidth={"lg"}
          fullWidth={true}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Account Number
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Account Name
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Status
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.accountNumber}>
                    <TableCell component="th" scope="row">
                      {row.accountNumber}
                    </TableCell>
                    <TableCell>{row.accountName}</TableCell>
                    <TableCell align="left">
                      <Chip
                        label={row.success}
                        variant="outlined"
                        color={row.success == "Success" ? "success" : "error"}
                        sx={{ width: "85px" }}
                      />
                    </TableCell>
                    <TableCell align="justify">{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <DialogActions>
            <Button onClick={handleCloseRequestDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Dialog
          open={openErrorDialog}
          keepMounted
          onClose={handleCloseErrorDialog}
        >
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Oops something went wrong! {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Dialog
          open={loading}
          keepMounted
          maxWidth={'xs'}
          fullWidth={true}
        >
          <DialogContent className="dialog-content-centered">
            <CircularProgress size={70}/>
            <DialogContentText className="loading-text">
              Loading...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </Container>
  );
}

export default FormRenderer;
