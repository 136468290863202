export default class ImmsAccountResponse {
  accountNumber: string;
  accountName: string;
  success: string;
  description: string;
  constructor(
    accountNumber: string,
    accountName: string,
    success: string,
    description: string
  ) {
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.success = success;
    this.description = description;
  }

}
