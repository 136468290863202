import { AccountDetails } from "./AccountDetails";

class MmdAccountFormModel {
  private accountDetails: AccountDetails[];
  private accountBranchNumber: string;
  private accountCurrency: string;
  private lineOfBusiness: string;
  private accountType: string;
  private accountCostCenterNumber: string;
  private accountEntityCode: string;
  private accountGlCategoryCode: string;
  private industryClass: string;
  private networkIndicator: string;
  private legalEntityCash: string;
  private businessType: string;
  private compId111CostCenter: string;
  private foreignGovernmentAccount: boolean;
  private usBranch31CashReserve: boolean;
  private reservedStatus: boolean;
  private isGbpHybrid: boolean;
  private isAccountStif: boolean;
  private isUsageCres: boolean;
  private cnyOffShore: boolean;

  constructor(formData) {
    this.accountDetails = formData.accountDetails;
    this.accountBranchNumber = formData.accountBranchNumber;
    this.accountCurrency = formData.accountCurrency;
    this.lineOfBusiness = formData.lineOfBusiness;
    this.accountCostCenterNumber = formData.accountCostCenterNumber;
    this.accountType = formData.accountType;
    this.accountGlCategoryCode = formData.accountGlCategoryCode;
    this.industryClass = formData.industryClass;
    this.networkIndicator = formData.networkIndicator;
    this.legalEntityCash = formData.legalEntityCash;
    this.businessType = formData.businessType;
    this.compId111CostCenter = formData.compId111CostCenter;
    this.foreignGovernmentAccount = formData.foreignGovernmentAccount;
    this.usBranch31CashReserve = formData.usBranch31CashReserve;
    this.isGbpHybrid = formData.isGbpHybrid;
    this.accountEntityCode = formData.accountEntityCode;
    this.isAccountStif = formData.isAccountStif;
    this.reservedStatus = formData.reservedStatus;
    this.isUsageCres = formData.isUsageCres;
    this.cnyOffShore = formData.cnyOffShore;
  }

  validate() {
    if (this.accountBranchNumber === null) {
      return "Account Branch Number is required";
    }
    if (this.accountCurrency === null) {
      return "Account Currency is required";
    }
    if (this.lineOfBusiness === null) {
      return "Line of Business is required";
    }
    if (this.accountCostCenterNumber === null) {
      return "Account Cost Center Number is required";
    }
    if (this.accountType === null) {
      return "Account Type is required";
    }
    if (this.accountGlCategoryCode === null) {
      return "Account Category Code is required";
    }
    if (this.industryClass === null) {
      return "Industry Class is required";
    }
    if (this.networkIndicator === null) {
      return "Network Indicator is required";
    }
    if (this.legalEntityCash === null) {
      return "Legal Entity Cash is required";
    }
    if (this.businessType === null) {
      return "Business Type is required";
    }
    if (this.compId111CostCenter === null) {
      return "Comp Id 111 Cost Center is required";
    }
    if (this.usBranch31CashReserve === null) {
      return "US Branch 31 Cash Reserve is required";
    }
    if (this.foreignGovernmentAccount === null) {
      return "Foreign Government Account is required";
    }
    if (this.isGbpHybrid === null) {
      return "Is GBP Hybrid is required";
    }
    if (this.isAccountStif === null) {
      return "Is Account Stif is required";
    }
    if (this.accountEntityCode === null) {
      return "Account Entity Code is required";
    }
    return null;
  }

  toJson() {
    return {
      accountDetails: this.accountDetails,
      accountBranchNumber: this.accountBranchNumber,
      accountCurrency: this.accountCurrency,
      lineOfBusiness: this.lineOfBusiness,
      accountCostCenterNumber: this.accountCostCenterNumber,
      accountType: this.accountType,
      accountGlCategoryCode: this.accountGlCategoryCode,
      industryClass: this.industryClass,
      networkIndicator: this.networkIndicator,
      legalEntityCash: this.legalEntityCash,
      businessType: this.businessType,
      compId111CostCenter: this.compId111CostCenter,
      foreignGovernmentAccount: this.foreignGovernmentAccount,
      usBranch31CashReserve: this.usBranch31CashReserve,
      isGbpHybrid: this.isGbpHybrid,
      accountEntityCode: this.accountEntityCode,
      isAccountStif: this.isAccountStif,
      reservedStatus: this.reservedStatus,
      isUsageCres: this.isUsageCres,
      cnyOffshore: this.cnyOffShore,
    };
  }
}
export default MmdAccountFormModel;
